<template>
  <div class="bg-grey">
    <div class="we">
      <div class="myBody">
        <div class="aboutUs">
          <h1>公司简介</h1>
          <p class="brief">
            杭州河小象网络科技有限公司，以“书法美育”为主打，专注为孩子提供硬笔书法、软笔书法、国画等素质教育。采用“在线学习＋点评1V1”教学特色，旨在通过创新科技和优质内容，培养孩子的文化内涵和艺术审美，帮助孩子提高未来竞争力。
          </p>
          <h2>企业使命</h2>
          <p class="msg">
            为孩子提供有效、有趣的普惠教育
          </p>

          <h2>联系我们</h2>
          <p class="msg">
            联系电话：15394212635<br />
            地址：浙江省杭州市余杭区仓前街道龙园路88号3号楼12层B1212室
          </p>          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'aboutHxx',
  data() {
    return {
      data: {}
    }
  },
  created() {
  },
  methods: {    
  }
}
</script>
<style lang="scss" scoped>
.bg-grey {
  background-color: #F6F6F9;

  .we {
    padding: 60px 20px;
    box-sizing: border-box;
    width: 1120px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .menu {
      background-color: #fff;
      max-height: 323px;
    }

    .myBody {
      width: 800px;
    }
  }
}

.aboutUs {
  text-align: left;

  h1 {
    font-size: 50px;
    color: #444144;
    font-weight: bold;
  }

  .brief {
    font-size: 18px;
    color: #444144;
    line-height: 34px;
    text-indent: 2em;
    margin: 28px 0 30px;
  }

  h2 {
    color: #FF7041;
    font-size: 32px;
    font-weight: bold;
  }

  .msg {
    font-size: 18px;
    color: #444144;
    line-height: 30px;
    margin: 17px 0 38px;
  }

  .msg li span {
    font-weight: bold;
    margin-right: 10px;
  }

  .msg p {
    text-indent: 2em;
  }

  .msg-brief {
    font-size: 18px;
    color: #444144;
    line-height: 34px;
    text-indent: 2em;
    margin: 28px 0 30px;
  }
}</style>
